import faoLogo from '../../assets/fao-logo-sdg.svg'
import facebook from '../../assets/social-icons/social-icon-facebook.svg'
import flickr from '../../assets/social-icons/social-icon-flickr.svg'
import instagram from '../../assets/social-icons/social-icon-instagram.svg'
import linkedin from '../../assets/social-icons/social-icon-linkedin.svg'
import rss from '../../assets/social-icons/social-icon-rss.svg'
import slideshare from '../../assets/social-icons/social-icon-slideshare.svg'
import soundcloud from '../../assets/social-icons/social-icon-facebook.svg'
import tiktok from '../../assets/social-icons/social-icon-tiktok.svg'
import tuotiao from '../../assets/social-icons/social-icon-tuotiao.svg'
import twitter from '../../assets/social-icons/social-icon-twitter.svg'
import wechat from '../../assets/social-icons/social-icon-wechat.svg'
import weibo from '../../assets/social-icons/social-icon-weibo.svg'
import youtube from '../../assets/social-icons/social-icon-youtube.svg'
import appstore from '../../assets/download-app/app-store.svg'
import googleplay from '../../assets/download-app/google-play.svg'



const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="footer-logo col-md-7">
                        <a href="/home/en/">
                            <img src={faoLogo} alt="Food and Agriculture Organization of the United Nations" title="Food and Agriculture Organization of the United Nations" />
                        </a>
                    </div>
                    <div className="footer-social col-md-5">
                        <h6 className="title-category">Follow us on</h6>
                        <ul className="list-inline social-icons"> 
                        <li className="list-inline-item">
                            <a href="#!" alt="facebook" title="facebook" target="_blank">
                                <img src={facebook} alt="facebook" />
                            </a> 
                        </li>
                        <li className="list-inline-item">
                            <a href="#!" alt="flickr" title="flickr" target="_blank">
                                <img src={flickr} alt="flickr" />
                            </a> 
                        </li>
                        <li className="list-inline-item">
                            <a href="#!" alt="instagram" title="instagram" target="_blank">
                                <img src={instagram} alt="instagram" />
                            </a> 
                        </li>
                        <li className="list-inline-item">
                            <a href="#!" alt="linkedin" title="linkedin" target="_blank">
                                <img src={linkedin} alt="linkedin" />
                            </a> 
                        </li>
                        <li className="list-inline-item">
                            <a href="#!" alt="rss" title="rss" target="_blank">
                                <img src={rss} alt="rss" />
                            </a> 
                        </li>
                        <li className="list-inline-item">
                            <a href="#!" alt="slideshare" title="slideshare" target="_blank">
                                <img src={slideshare} alt="slideshare" />
                            </a> 
                        </li>
                        <li className="list-inline-item">
                            <a href="#!" alt="soundcloud" title="soundcloud" target="_blank">
                                <img src={soundcloud} alt="soundcloud" />
                            </a> 
                        </li>
                        <li className="list-inline-item">
                            <a href="#!" alt="tiktok" title="tiktok" target="_blank">
                                <img src={tiktok} alt="tiktok" />
                            </a> 
                        </li>
                        <li className="list-inline-item">
                            <a href="#!" alt="tuotiao" title="tuotiao" target="_blank">
                                <img src={tuotiao} alt="tuotiao" />
                            </a> 
                        </li>
                        <li className="list-inline-item">
                            <a href="#!" alt="twitter" title="twitter" target="_blank">
                                <img src={twitter} alt="twitter" />
                            </a> 
                        </li>
                        <li className="list-inline-item">
                            <a href="#!" alt="wechat" title="wechat" target="_blank">
                                <img src={wechat} alt="wechat" />
                            </a> 
                        </li>
                        <li className="list-inline-item">
                            <a href="#!" alt="weibo" title="weibo" target="_blank">
                                <img src={weibo} alt="weibo" />
                            </a> 
                        </li>
                        <li className="list-inline-item">
                            <a href="#!" alt="youtube" title="youtube" target="_blank">
                                <img src={youtube} alt="youtube" />
                            </a> 
                        </li>
                        </ul>
                    </div>
                </div>

                <div className="row">
                    <div className="footer-links col-md-7">
                        <div className="footer-links-top">
                            <div className="">
                                <a href="#!" target="_top">FAO Organizational Chart</a>
                            </div>
                            <div className="btn-group dropup">
                                <button type="button" className="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Worldwide Offices
                                </button>
                                <div className="dropdown-menu">
                                    <a href="#!" className="dropdown-item">Regional Office for Africa</a>
                                    <a href="#!" className="dropdown-item">Regional Office for Asia and the Pacific</a>
                                    <a href="#!" className="dropdown-item">Regional Office for Europe and Central Asia</a>
                                    <a href="#!" className="dropdown-item">Regional Office for Latin America and the Caribbean</a>
                                    <a href="#!" className="dropdown-item">Regional Office for the Near East and North Africa</a>
                                    <a href="#!" className="dropdown-item">Country Offices</a>
                                </div>
                            </div>
                        </div>
                        <div className="footer-links-bottom">
                            <ul className="list-inline">
                                <li className="list-inline-item">
                                    <a href="#!" target="_top">Jobs</a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="#!" target="_top">Contact us</a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="#!" target="_top">Terms and Conditions</a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="#!" target="_top">Scam Alert</a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="#!" target="_top">Report Misconduct</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer-download col-md-5">
                        <h6 className="title-category">Download our app</h6>
                        <ul className="list-inline">
                            <li className="list-inline-item">
                                <a href="#!" target="_blank">
                                    <img src={appstore} alt="Apple Store" />
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a href="#!" target="_blank">
                                    <img src={googleplay} alt="Google Play" />
                                </a>
                            </li>
                        </ul>
                        <a className="copyright" href="/contact-us/terms/en/">© FAO&nbsp;2021</a>
                    </div>
                </div>
            </div>
        </footer>
    );
}
  
export default Footer;