import { Date } from '../typo/Typo'
import listImage from '../../../assets/test-images/card-test.jpg'


const ListAudio = props => {
    return (
        <div className={`d-list d-list-audio${props.player ? ' d-list-player' : ''}`}>
            { props.player ? player(props) : image(props) }
            <div className="d-list-content">
                <h5 className="title-link">
                    <a href="#!">{ props.title || 'Lorem ipsum dolor sit amet' }</a>
                </h5>
                <Date>{ props.date || '26/06/2020' }</Date>
                <p>{ props.text || 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque autem corrupti consequuntur tempore. Numquam, facere? Natus nisi aliquid a cumque, incidunt deleniti atque quibusdam adipisci recusandae repellendus labore. Neque, placeat.' }</p>

            </div>
        </div>
    );
}

const image = props => {
    return(
        <div className="d-list-visual ratio ratio-3x2">
            <a href="#!"><img src={ props.image || listImage} alt={ props.title || 'Lorem ipsum dolor sit amet' } /></a>
        </div>
    );
}

const player = props => {
    return(
        <div className="d-list-visual">
            <iframe title="Iframe title" width="100%" height="100%" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1176941233&amp;color=%23116aab&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false&amp;show_teaser=true"></iframe>
        </div>
    );
}

  
export default ListAudio;
