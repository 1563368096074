import { Date } from '../typo/Typo'
import listImage from '../../../assets/test-images/card-test.jpg'


const ListNews = props => {
    return (
        <div className="d-list d-list-news">
            <div className="d-list-visual ratio ratio-3x2">
                <img src={ props.image || listImage} alt={ props.title || 'Lorem ipsum dolor sit amet' } />
            </div>
            <div className="d-list-content">
                <h5 className="title-link">
                    <a href="#!">{ props.title || 'Lorem ipsum dolor sit amet' }</a>
                </h5>
                <Date>{ props.date || '26/06/2020' }</Date>
                <p>{ props.text || 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque autem corrupti consequuntur tempore. Numquam, facere? Natus nisi aliquid a cumque, incidunt deleniti atque quibusdam adipisci recusandae repellendus labore. Neque, placeat.' }</p>
            </div>
        </div>
    );
}
  
export default ListNews;
