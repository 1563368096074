import { Date } from '../typo/Typo'


const ListSpeeches = props => {
    return (
        <div className="d-list d-list-speeches">
            <div className="d-list-content">
                <h5 className="title-link">
                    <a href="#!">{ props.title || 'Lorem ipsum dolor sit amet' }</a>
                </h5>
                <Date>{ props.date || '26/06/2020' } - Rome</Date>
                <p>{ props.text || 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque autem corrupti consequuntur tempore. Numquam, facere? Natus nisi aliquid a cumque, incidunt deleniti atque quibusdam adipisci recusandae repellendus labore. Neque, placeat.' }</p>
            </div>
        </div>
    );
}
  
export default ListSpeeches;
