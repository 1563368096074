import { Date } from '../typo/Typo'
import { TagsList } from '../FaoComponents';
import listImage from '../../../assets/test-images/card-test.jpg'


const ListStory = props => {
    return (
        <div className="d-list d-list-story">
            <div className="d-list-visual ratio ratio-3x2">
                <img src={ props.image || listImage} alt={ props.title || 'Lorem ipsum dolor sit amet' } />
            </div>
            <div className="d-list-content">
                <h5 className="title-link">
                    <a href="#!">{ props.title || 'Lorem ipsum dolor sit amet' }</a>
                </h5>
                <Date>{ props.date || '26/06/2020' }</Date>
                <TagsList />
                <div className="d-flex align-items-center">
                    <span className="me-2 mb-2">
                    <b>SDGs: </b>
                    </span>
                    <span className="sdg-small sdg-3 me-2 mb-2"></span>
                    <span className="sdg-small sdg-4 me-2 mb-2"></span>
                    <span className="sdg-small sdg-5 me-2 mb-2"></span>
                    <span className="sdg-small sdg-6 me-2 mb-2"></span>
                </div>
            </div>
        </div>
    );
}
  
export default ListStory;
